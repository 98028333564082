import { EventEmitter } from 'events';

import {
  Company,
  Location,
  Maybe,
  Movie,
  Showtime,
  ShowtimeData,
  Theater,
  Ticketing
} from 'types/graphql-api.generated';

import { Item } from 'common/components/ui/types';
import { ShowtimesExperiences } from 'common/tools/showtime/getShowtimesTechno';
import { SocialActionEntity } from 'common/tracking/socialActionTracking';

import { Filter } from 'website/containers/showtimes/utils/types';
import { Entity } from 'website/types';

// nomenclature: TRACKING _ COMPONENT NAME _ ACTION
export const FOLLOW_COLLECTION_EVENT = 'FOLLOW_COLLECTION_EVENT';
export const UNFOLLOW_COLLECTION_EVENT = 'UNFOLLOW_COLLECTION_EVENT';
export const DELETE_WANT_TO_SEE_EVENT = 'DELETE_WANT_TO_SEE_EVENT';

export enum TrackingEventNames {
  ADD_COLLECTION_BUTTON_CLICK_EVENT = 'ADD_COLLECTION_BUTTON_CLICK_EVENT',
  ADD_ENTITIES_BUTTON_CLICK = 'ADD_ENTITIES_BUTTON_CLICK',
  ADD_ENTITIES_TO_COLLECTION_EVENT = 'ADD_ENTITIES_TO_COLLECTION_EVENT',
  ADD_NOT_INTERESTED_EVENT = 'ADD_NOT_INTERESTED_EVENT',
  ADD_OPINION_EVENT = 'ADD_OPINION_EVENT',
  ADD_PERSONAL_COLLECTION_EVENT = 'ADD_PERSONAL_COLLECTION_EVENT',
  ADD_REVIEW_BUTTON_CLICK_EVENT = 'ADD_REVIEW_BUTTON_CLICK_EVENT',
  ADD_SEEN_IT_EVENT = 'ADD_SEEN_IT_EVENT',
  ADD_USER_THEATER_EVENT = 'ADD_USER_THEATER_EVENT',
  ADD_WANT_TO_SEE_EVENT = 'ADD_WANT_TO_SEE_EVENT',
  AFFINITY_TOOLTIP_EVENT = 'AFFINITY_TOOLTIP_EVENT',
  AUTO_SUGGEST_SEARCH_EMERGENCE = 'AUTO_SUGGEST_SEARCH_EMERGENCE',
  BOTTOM_ADS_DETECTED = 'BOTTOM_ADS_DETECTED',
  DELETE_OPINION_EVENT = 'DELETE_OPINION_EVENT',
  DELETE_WANT_TO_SEE_EVENT = 'DELETE_WANT_TO_SEE_EVENT',
  FACEBOOK_FRIENDS_FOLLOWED_EVENT = 'FACEBOOK_FRIENDS_FOLLOWED_EVENT',
  FOLLOW_COLLECTION_EVENT = 'FOLLOW_COLLECTION_EVENT',
  FOLLOW_SERIES_EVENT = 'FOLLOW_SERIES_EVENT',
  FOLLOW_USER_EVENT = 'FOLLOW_USER_EVENT',
  NEWSLETTER_PUSH = 'NEWSLETTER_PUSH',
  ONBOARDING_NEXT_STEP_CLICK = 'ONBOARDING_NEXT_STEP_CLICK',
  ON_CLICK_THEATER_TOOLTIP = 'ON_CLICK_THEATER_TOOLTIP',
  ON_HOVER_THEATER_TOOLTIP = 'ON_HOVER_THEATER_TOOLTIP',
  ON_SELECT_AUTO_SUGGEST_SEARCH = 'ON_SELECT_AUTO_SUGGEST_SEARCH',
  ON_SELECT_AUTO_SUGGEST_SEARCH_EMERGENCE = 'ON_SELECT_AUTO_SUGGEST_SEARCH_EMERGENCE',
  OPEN_ACCESSIBILITY_MODAL = 'OPEN_ACCESSIBILITY_MODAL',
  OPEN_PERMANENT_SPEEDNOTER_BUTTON_CLICK = 'OPEN_PERMANENT_SPEEDNOTER_BUTTON_CLICK',
  OPEN_SHOWTIME_MODAL = 'OPEN_SHOWTIME_MODAL',
  PROMOTE_AFFINITY_CLICK = 'PROMOTE_AFFINITY_CLICK',
  PROMOTE_SIGNUP_CLICK = 'PROMOTE_SIGNUP_CLICK',
  REMOVE_ENTITIES_FROM_COLLECTION_EVENT = 'REMOVE_ENTITIES_FROM_COLLECTION_EVENT',
  REMOVE_PERSONAL_COLLECTION_EVENT = 'REMOVE_PERSONAL_COLLECTION_EVENT',
  REMOVE_SEEN_IT_EVENT = 'REMOVE_SEEN_IT_EVENT',
  REMOVE_USER_THEATER_EVENT = 'REMOVE_USER_THEATER_EVENT',
  REMOVE_WANT_TO_SEE_EVENT = 'REMOVE_WANT_TO_SEE_EVENT',
  SHARE_COLLECTION = 'SHARE_COLLECTION',
  THEATER_RESERVATION_LINK_CLICK_EVENT = 'THEATER_RESERVATION_LINK_CLICK_EVENT',
  TRACKING_LOGIN_REQUEST = 'TRACKING_LOGIN_REQUEST',
  TRACKING_ROLLER_DATE_ITEM_HANDLE_CLICK = 'TRACKING_ROLLER_DATE_ITEM_HANDLE_CLICK',
  TRACKING_SHOWTIME_FILTER_BUTTON_HANDLE_CLICK = 'TRACKING_SHOWTIME_FILTER_BUTTON_HANDLE_CLICK',
  UNFOLLOW_COLLECTION_EVENT = 'UNFOLLOW_COLLECTION_EVENT',
  UNFOLLOW_SERIES_EVENT = 'UNFOLLOW_SERIES_EVENT',
  UNFOLLOW_USER_EVENT = 'UNFOLLOW_USER_EVENT',
  UPDATE_OPINION_EVENT = 'UPDATE_OPINION_EVENT',
  UPDATE_PERSONAL_COLLECTION_EVENT = 'UPDATE_PERSONAL_COLLECTION_EVENT'
}

export type SocialActionPlacements =
  | 'standalone'
  | 'actions_booster'
  | 'modal'
  | 'entities_onboarding'
  | 'affinity_score';

export type TrackingContextObject = {
  context: TrackingContext;
};

export type TrackingContext = {
  entity?: Entity;
  socialActionPlacement: SocialActionPlacements;
};

export type NewsLetterPushParams = {
  eventAction: 'popup' | 'toaster' | 'native';
  eventLabel: 'impression' | 'close' | 'clic';
  subject:
    | 'movies_releases'
    | 'series_news'
    | 'favorite_theaters'
    | 'all_newsletter';
};

export type ReservationTrackingMovie = Pick<
  Movie,
  'genres' | 'internalId' | 'title'
>;

export type ReservationTrackingShowtime = ShowtimesExperiences &
  Pick<
    Showtime,
    '__typename' | 'diffusionVersion' | 'isPreview' | 'service' | 'startsAt'
  > & {
    data?: Maybe<
      Pick<ShowtimeData, '__typename'> & {
        ticketing?: Maybe<
          Array<Maybe<Pick<Ticketing, '__typename' | 'provider' | 'urls'>>>
        >;
      }
    >;
  };
export type ReservationTrackingTheater = Pick<
  Theater,
  'internalId' | 'name'
> & {
  location?: Maybe<Pick<Location, 'city' | 'region' | 'zip'>>;
  theaterCircuits?: Maybe<Pick<Company, 'internalId' | 'name'>>;
};

export type TheaterReservationTracking = {
  movie?: ReservationTrackingMovie;
  showtime?: ReservationTrackingShowtime;
  theater?: ReservationTrackingTheater;
  ga4event?: string;
  ga4data?: Record<string, any>;
  ga4dataOverwrite?: Record<string, any>;
};

export type RollerDateItemClickTracking = {
  date?: string;
  movie?: Pick<Movie, 'internalId' | 'title'>;
  theater?: Pick<Theater, 'internalId' | 'name'>;
};

export type ShowtimeFilterButtonTracking = {
  movie?: Pick<Movie, 'internalId' | 'title'>;
  theater?: Pick<Theater, 'internalId' | 'name'>;
  filters?: Filter[] | null;
};

export type TheaterHeaderTooltipTracking = {
  jan?: Record<string, string>;
};

export type OnSelectAutoSuggestSearchTracking = {
  index: number;
  item: Item;
  searchValue: string;
};

export type AutoSuggestSearchEmergenceTracking = {
  item: Item;
};

export type WantToSeeTracking = {
  entity: SocialActionEntity | SocialActionEntity[];
};

export type AddNotInterestedTracking =
  | SocialActionEntity
  | SocialActionEntity[];

export type OpinionTracking = {
  entity: SocialActionEntity;
  isReview?: boolean;
  socialActionPlacement?: string | null;
};

export type UserTheaterTracking = TrackingContextObject & {
  internalId?: string;
  name?: string;
};

export interface TrackingEventEmitter extends EventEmitter {
  on(
    event:
      | TrackingEventNames.ADD_OPINION_EVENT
      | TrackingEventNames.ADD_SEEN_IT_EVENT
      | TrackingEventNames.ADD_WANT_TO_SEE_EVENT
      | TrackingEventNames.DELETE_OPINION_EVENT
      | TrackingEventNames.DELETE_WANT_TO_SEE_EVENT
      | TrackingEventNames.FOLLOW_COLLECTION_EVENT
      | TrackingEventNames.FOLLOW_USER_EVENT
      | TrackingEventNames.REMOVE_SEEN_IT_EVENT
      | TrackingEventNames.UNFOLLOW_COLLECTION_EVENT
      | TrackingEventNames.UNFOLLOW_USER_EVENT
      | TrackingEventNames.UPDATE_OPINION_EVENT,
    fn: (context: TrackingContextObject) => void
  ): this;
  on(
    event: TrackingEventNames.ONBOARDING_NEXT_STEP_CLICK,
    fn: (context: 'theaters' | 'friends' | 'ratings') => void
  ): this;
  on(
    event:
      | TrackingEventNames.AFFINITY_TOOLTIP_EVENT
      | TrackingEventNames.TRACKING_LOGIN_REQUEST,
    fn: (obj: any) => any
  ): this;
  on(
    event: TrackingEventNames.NEWSLETTER_PUSH,
    fn: (params: NewsLetterPushParams) => void
  ): this;
  on(
    event: TrackingEventNames.OPEN_PERMANENT_SPEEDNOTER_BUTTON_CLICK,
    fn: (entity: Entity) => any
  ): this;
  on(
    event:
      | TrackingEventNames.PROMOTE_AFFINITY_CLICK
      | TrackingEventNames.PROMOTE_SIGNUP_CLICK,
    fn: () => void
  ): this;
  on(
    event: TrackingEventNames.THEATER_RESERVATION_LINK_CLICK_EVENT,
    fn: (tracking: TheaterReservationTracking) => void
  ): this;
  on(
    event: TrackingEventNames.TRACKING_ROLLER_DATE_ITEM_HANDLE_CLICK,
    fn: (tracking: RollerDateItemClickTracking) => void
  ): this;
  on(
    event: TrackingEventNames.TRACKING_SHOWTIME_FILTER_BUTTON_HANDLE_CLICK,
    fn: (tracking: ShowtimeFilterButtonTracking) => void
  ): this;
  on(
    event:
      | TrackingEventNames.ON_CLICK_THEATER_TOOLTIP
      | TrackingEventNames.ON_HOVER_THEATER_TOOLTIP,
    fn: (tracking: TheaterHeaderTooltipTracking) => void
  ): this;
  on(
    event:
      | TrackingEventNames.ON_SELECT_AUTO_SUGGEST_SEARCH
      | TrackingEventNames.ON_SELECT_AUTO_SUGGEST_SEARCH_EMERGENCE,
    fn: (tracking: OnSelectAutoSuggestSearchTracking) => void
  ): this;
  on(
    event: TrackingEventNames.AUTO_SUGGEST_SEARCH_EMERGENCE,
    fn: (tracking: AutoSuggestSearchEmergenceTracking) => void
  ): this;
  on(
    event:
      | TrackingEventNames.ADD_WANT_TO_SEE_EVENT
      | TrackingEventNames.REMOVE_WANT_TO_SEE_EVENT,
    fn: (tracking: WantToSeeTracking) => void
  ): this;
  on(
    event: TrackingEventNames.ADD_NOT_INTERESTED_EVENT,
    fn: (entities: AddNotInterestedTracking) => void
  ): this;
  on(
    event:
      | TrackingEventNames.ADD_OPINION_EVENT
      | TrackingEventNames.DELETE_OPINION_EVENT
      | TrackingEventNames.UPDATE_OPINION_EVENT,
    fn: (entities: OpinionTracking) => void
  ): this;
  on(
    event:
      | TrackingEventNames.ADD_USER_THEATER_EVENT
      | TrackingEventNames.REMOVE_USER_THEATER_EVENT,
    fn: (context: UserTheaterTracking) => void
  ): this;
}

const socialActionPlacementsArray: SocialActionPlacements[] = [
  'standalone',
  'actions_booster',
  'modal',
  'entities_onboarding',
  'affinity_score'
];

export const isSocialActionPlacements = (
  candidate: any
): candidate is SocialActionPlacements =>
  socialActionPlacementsArray.includes(candidate);

export const HAS_DOLBY_UPSALE = 'upsale';
